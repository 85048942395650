import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Components/Header";
import Subheader from './Components/Sub_Header';
import Footer from "./Components/Footer";
import Loader from "./Components/Loader";

const Main = React.lazy(() => import("./Components/Main")),
Category = React.lazy(() => import("./Components/Category")),
Product_Details = React.lazy(() => import("./Components/Product_Details")),
Http404 = React.lazy(() => import("./Components/Http404")),
Basket = React.lazy(() => import("./Components/Basket")),
Delivery = React.lazy(() => import("./Components/Delivery")),
Payment = React.lazy(() => import("./Components/Payment")),
ContactUs = React.lazy(() => import("./Components/ContactUs")),
AboutUs = React.lazy(() => import("./Components/AboutUs")),
Photo_Upload = React.lazy(() => import("./Components/Photo_Upload")),
Search = React.lazy(() => import("./Components/Search")),
Account = React.lazy(() => import("./Components/Account")),
Profile = React.lazy(() => import("./Components/Profile")),
Photo_Uploadv2 = React.lazy(() => import("./Components/Photo_Uploadv2"));


function App() {
  const paths = ["/photo-upload","/photo-upload-2"];
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        {paths.includes(window.location.pathname) ? <Subheader/> : <Header/>}
        <Switch>
          <Route exact path="/" component={Main} />
          <Route path="/category" component={Category} />
          <Route path="/details" component={Product_Details} />
          <Route path="/basket" component={Basket} />
          <Route path="/delivery" component={Delivery} />
          <Route path="/payment" component={Payment} />
          <Route path="/photo-upload" component={Photo_Upload} />
          <Route path="/photo-upload-2" component={Photo_Uploadv2} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/search" component={Search} />
          <Route path="/account" component={Account} />
          <Route path="/profile" component={Profile} />
          <Route component={Http404} />
        </Switch>
        <Footer />
      </Router>
    </Suspense>
  );
}

export default App;

