import React, { useState, useRef } from "react";

export default function Sub_Header() {
  const goBack = useRef(),
    btnMobilePreview = useRef(),
    btnPreview = useRef();
  const [toggle, setToggle] = useState(false);

  const remove = () => {
    btnPreview.current.classList.remove("green");
    btnMobilePreview.current.classList.remove("green");
    goBack.current.classList.remove("white");
    btnPreview.current.innerText = "Önizle";
    btnMobilePreview.current.innerText = "Önizle";
  };

  const openModal = (e) => {
    setToggle(!toggle);
    e.target.classList.toggle("green");
    if (e.target.classList.contains("green")) {
      btnPreview.current.classList.add("green");
      btnMobilePreview.current.classList.add("green");
      goBack.current.classList.add("white");
      btnPreview.current.innerText = "Onay";
      btnMobilePreview.current.innerText = "Onay";
    } else {
      remove();
    }
  };

  const closeModal = (e) => {
    if (e.target.classList.contains("preview-overlay")) {
      setToggle(!toggle);
      remove();
    }
  };

  return (
    <header className="sub" ref={goBack}>
      <div className="navbar container">
        <div className="logo">
          <a href="/">
            <img
              className="lazy"
              src="img/logo/logo.svg"
              loading="lazy"
              alt="Resimli Magnet"
            />
          </a>
        </div>
        <h1>Fotoğraf Yükle</h1>
        <div className="btn-preview" onClick={openModal}>
          <button ref={btnPreview}>Önizle</button>
        </div>
      </div>
      <nav className="container">
        <div className="go-back">
          <a href="/">
            <span className="arrow"></span> Geri
          </a>
        </div>
        <div className="btn-preview" onClick={openModal}>
          <button ref={btnMobilePreview}>Önizle</button>
        </div>
      </nav>
      {toggle && (
        <div className="preview-overlay" onClick={(e) => closeModal(e)}>
          <div className="container body">
            <div className="gr-3">
              <div className="wrap">
                <div className="box">
                  <div className="title">Yazı Rengi</div>
                  <ul>
                    <li className="red"></li>
                    <li className="orange"></li>
                    <li className="green"></li>
                    <li className="grey"></li>
                  </ul>
                </div>
                <div className="box">
                  <div className="title">Yazı Dış Çizgisi</div>
                  <ul>
                    <li className="red-br"></li>
                    <li className="orange-br"></li>
                    <li className="green-br"></li>
                    <li className="grey-br"></li>
                  </ul>
                  <div className="input-group">
                    <label htmlFor="check">
                      <input type="checkbox" id="check" name="check" />
                      <span>Tüm fotoğraflara uygula</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="picture-wrap">
                <div className="picture">
                  <img src="/img/category/img_01.png" alt="" />
                </div>
                <div className="edit">
                  <input
                    type="text"
                    name="msg"
                    id="mst"
                    placeholder="Mesajını Yaz"
                  />
                </div>
                <div className="configure">
                  <div className="corp">
                    <img src="img/photo-upload/corp.svg" alt="" />
                  </div>
                  <div className="scale">
                    <div className="anti-clockwise">
                      <img
                        src="img/photo-upload/anticlockwise-line.svg"
                        alt=""
                      />
                    </div>
                    <div className="clockwise">
                      <img src="img/photo-upload/clockwise-line.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrap">
                <div className="selection">
                  <select name="font-type" id="font-type">
                    <option value="Yazı Tipi" selected className="font-1">
                      Yazı Tipi
                    </option>
                    <option value="Yazı Tipi" className="font-2">
                      Yazı Tipi
                    </option>
                    <option value="Yazı Tipi" className="font-3">
                      Yazı Tipi
                    </option>
                    <option value="Yazı Tipi" className="font-4">
                      Yazı Tipi
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}
