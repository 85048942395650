import React, { useState, useEffect, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

export default function Header() {
  const [isLogin, setLogin] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [change, setChangeTab] = useState(false);

  const loginTab = useRef(),
    accountTab = useRef();

  useEffect(() => {
    setLogin(true);
  }, []);

  const changeLoginTab = () => {
    loginTab.current.classList.add("active");
    if (accountTab.current.classList.contains("active")) {
      accountTab.current.classList.remove("active");
    }
    setChangeTab(false);
  };

  const changeAccountTab = () => {
    accountTab.current.classList.add("active");
    if (loginTab.current.classList.contains("active")) {
      loginTab.current.classList.remove("active");
    }
    setChangeTab(true);
  };

  const openModal = () => {
    setToggle(!toggle);
  };

  const closeModal = (e) => {
    if (e.target.classList.contains("overlay")) {
      setToggle(!toggle);
    }
  };

  return (
    <header>
      <div className="navbar container">
        <div className="logo">
          <a href="/">
            <img
              className="lazy"
              src="img/logo/logo.svg"
              loading="lazy"
              alt="Resimli Magnet"
            />
            <div className="mobile-logo">
              <img
                className="lazy"
                src="img/footer/logo.svg"
                loading="lazy"
                alt="Resimli Magnet"
              />
            </div>
          </a>
        </div>
        <div className="search-bar">
          <form>
            <Typeahead
              id="search-area"
              labelKey={(option) => `${option.firstName} ${option.lastName}`}
              options={[
                { firstName: "Art", lastName: "Blakey" },
                { firstName: "John", lastName: "Coltrane" },
                { firstName: "Miles", lastName: "Davis" },
                { firstName: "Herbie", lastName: "Hancock" },
                { firstName: "Charlie", lastName: "Parker" },
                { firstName: "Tony", lastName: "Williams" },
              ]}
              placeholder="Magnet, poster, polakart, aksesuar ara"
            />
            <a href="/search">
            <img
              className="lazy"
              src="img/search-bar/ico.svg"
              loading="lazy"
              alt=""
            />
            </a>
          </form>
        </div>
        {!isLogin ? (
          <div className="login">
            <a onClick={openModal}>Giriş Yap/Üye Ol</a>
          </div>
        ) : (
          <div className="account">
            <div className="title">
              <span>Hesabım</span> <span className="arrow"></span>
              <ul>
                <li>
                  <a href="/profile">
                    <svg
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20ZM16 18V2H2V18H16ZM6 4V6C6 6.79565 6.31607 7.55871 6.87868 8.12132C7.44129 8.68393 8.20435 9 9 9C9.79565 9 10.5587 8.68393 11.1213 8.12132C11.6839 7.55871 12 6.79565 12 6V4H14V6C14 7.32608 13.4732 8.59785 12.5355 9.53553C11.5979 10.4732 10.3261 11 9 11C7.67392 11 6.40215 10.4732 5.46447 9.53553C4.52678 8.59785 4 7.32608 4 6V4H6Z" />
                    </svg>
                    Siparişlerim
                  </a>
                </li>
                <li>
                  <a href="/profile">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M21 20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V9.49C2.99989 9.33761 3.03462 9.18721 3.10152 9.0503C3.16841 8.91338 3.26572 8.79356 3.386 8.7L11.386 2.478C11.5615 2.34144 11.7776 2.2673 12 2.2673C12.2224 2.2673 12.4385 2.34144 12.614 2.478L20.614 8.7C20.7343 8.79356 20.8316 8.91338 20.8985 9.0503C20.9654 9.18721 21.0001 9.33761 21 9.49V20ZM19 19V9.978L12 4.534L5 9.978V19H19ZM7 15H17V17H7V15Z" />
                    </svg>
                    Adreslerim
                  </a>
                </li>
                <li>
                  <a href="/">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5 11H13V13H5V16L0 12L5 8V11ZM4 18H6.708C7.86269 19.0183 9.28669 19.6819 10.8091 19.9109C12.3316 20.14 13.8878 19.9249 15.291 19.2915C16.6942 18.6581 17.8849 17.6332 18.7201 16.3398C19.5553 15.0465 19.9995 13.5396 19.9995 12C19.9995 10.4604 19.5553 8.95354 18.7201 7.66019C17.8849 6.36683 16.6942 5.34194 15.291 4.7085C13.8878 4.07506 12.3316 3.85998 10.8091 4.08906C9.28669 4.31815 7.86269 4.98167 6.708 6H4C4.93066 4.75718 6.13833 3.74851 7.52707 3.05414C8.91581 2.35978 10.4473 1.99884 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.4473 22.0012 8.91581 21.6402 7.52707 20.9459C6.13833 20.2515 4.93066 19.2428 4 18Z" />
                    </svg>
                    Çıkış Yap
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}

        <a href="/basket">
          <div className="basket">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3.99999 5.41402L0.756989 2.17202L2.17199 0.757019L5.41399 4.00002H20.656C20.8119 4.00001 20.9656 4.03643 21.1049 4.10639C21.2441 4.17634 21.3652 4.27788 21.4582 4.40292C21.5513 4.52795 21.6138 4.673 21.6409 4.82651C21.6679 4.98001 21.6587 5.13771 21.614 5.28702L19.214 13.287C19.1522 13.4931 19.0257 13.6738 18.8531 13.8022C18.6805 13.9307 18.4711 14 18.256 14H5.99999V16H17V18H4.99999C4.73477 18 4.48042 17.8947 4.29288 17.7071C4.10535 17.5196 3.99999 17.2652 3.99999 17V5.41402ZM5.99999 6.00002V12H17.512L19.312 6.00002H5.99999ZM5.49999 22C5.10216 22 4.72063 21.842 4.43933 21.5607C4.15802 21.2794 3.99999 20.8978 3.99999 20.5C3.99999 20.1022 4.15802 19.7207 4.43933 19.4394C4.72063 19.1581 5.10216 19 5.49999 19C5.89781 19 6.27934 19.1581 6.56065 19.4394C6.84195 19.7207 6.99999 20.1022 6.99999 20.5C6.99999 20.8978 6.84195 21.2794 6.56065 21.5607C6.27934 21.842 5.89781 22 5.49999 22ZM17.5 22C17.1022 22 16.7206 21.842 16.4393 21.5607C16.158 21.2794 16 20.8978 16 20.5C16 20.1022 16.158 19.7207 16.4393 19.4394C16.7206 19.1581 17.1022 19 17.5 19C17.8978 19 18.2793 19.1581 18.5606 19.4394C18.842 19.7207 19 20.1022 19 20.5C19 20.8978 18.842 21.2794 18.5606 21.5607C18.2793 21.842 17.8978 22 17.5 22Z" />
            </svg>

            <div className="amount">1</div>
          </div>
        </a>
      </div>
      <nav>
        <div className="container">
          <ul>
            <li className="open">
              <a href="/details">Açılır Menü</a>
              <div className="nav-menu">
                <div className="container">
                  <div className="gr-3">
                    <div className="wrap">
                      <span className="title">20 TL’nin altında albümler</span>
                      <div className="content">
                        <figure>
                          <img
                            className="lazy"
                            src="img/photo-print/img_01.png"
                            alt=""
                          />
                          <figcaption>
                            Emoji Albüm 54 sticker fotoğraf + 63 emoji sticker
                            ve albüm
                          </figcaption>
                        </figure>
                        <div className="price">18 TL</div>
                      </div>
                      <div className="content">
                        <figure>
                          <img
                            className="lazy"
                            src="img/photo-print/img_02.png"
                            alt=""
                          />
                          <figcaption>
                            Emoji Albüm 54 sticker fotoğraf + 63 emoji sticker
                            ve albüm
                          </figcaption>
                        </figure>
                        <div className="price">58 TL</div>
                      </div>
                      <div className="content">
                        <figure>
                          <img
                            className="lazy"
                            src="img/photo-print/img_03.png"
                            alt=""
                          />
                          <figcaption>
                            Emoji Albüm 54 sticker fotoğraf + 63 emoji sticker
                            ve albüm
                          </figcaption>
                        </figure>
                        <div className="price">8 TL</div>
                      </div>
                      <div className="content">
                        <figure>
                          <img
                            className="lazy"
                            src="img/photo-print/img_04.png"
                            alt=""
                          />
                          <figcaption>
                            Emoji Albüm 54 sticker fotoğraf + 63 emoji sticker
                            ve albüm
                          </figcaption>
                        </figure>
                        <div className="price">28 TL</div>
                      </div>
                    </div>
                    <div className="wrap">
                      <span>En çok ilgi görenler</span>
                      <div className="content">
                        <figure>
                          <img
                            className="lazy"
                            src="img/photo-print/img_01.png"
                            alt=""
                          />
                          <figcaption>
                            Emoji Albüm 54 sticker fotoğraf + 63 emoji sticker
                            ve albüm
                          </figcaption>
                        </figure>
                        <div className="price">18 TL</div>
                      </div>
                      <div className="content">
                        <figure>
                          <img
                            className="lazy"
                            src="img/photo-print/img_02.png"
                            alt=""
                          />
                          <figcaption>
                            Emoji Albüm 54 sticker fotoğraf + 63 emoji sticker
                            ve albüm
                          </figcaption>
                        </figure>
                        <div className="price">58 TL</div>
                      </div>
                      <div className="content">
                        <figure>
                          <img
                            className="lazy"
                            src="img/photo-print/img_03.png"
                            alt=""
                          />
                          <figcaption>
                            Emoji Albüm 54 sticker fotoğraf + 63 emoji sticker
                            ve albüm
                          </figcaption>
                        </figure>
                        <div className="price">8 TL</div>
                      </div>
                      <div className="content">
                        <figure>
                          <img
                            className="lazy"
                            src="img/photo-print/img_04.png"
                            alt=""
                          />
                          <figcaption>
                            Emoji Albüm 54 sticker fotoğraf + 63 emoji sticker
                            ve albüm
                          </figcaption>
                        </figure>
                        <div className="price">28 TL</div>
                      </div>
                    </div>
                    <div className="wrap">
                      <div className="card">
                        <figure>
                          <img
                            className="lazy"
                            loading="lazy"
                            src="img/photo-print/img_01.png"
                            alt="Image 01"
                          />
                          <figcaption className="green">çok sevilen</figcaption>
                        </figure>
                        <div className="body">
                          <div className="title">
                            <span>Fotoğrafınızdan Magnet Baskı</span>
                            <small>100 Adet | 10x8cm</small>
                          </div>
                          <div className="price">
                            <span>18 TL'den</span>
                            <small>Başlayan fiyatlar</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="show-all">
                    <a href="index.html">
                      <span>Tüm Albüm Ürünleri</span>{" "}
                      <span className="arrow"></span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <a href="/details">Ürün Detay</a>
            </li>
            <li>
              <a href="/category">Kategori</a>
            </li>
            <li>
              <a href="/basket">Sepet</a>
            </li>
            <li>
              <a href="/delivery">Teslimat</a>
            </li>
            <li>
              <a href="/payment">Ödeme</a>
            </li>
            <li>
              <a href="/photo-upload">Foto Yükle</a>
            </li>
            <li>
              <a href="/photo-upload-2">Foto Yükle v2</a>
            </li>
            <li>
              <a href="/contact-us">İletişim</a>
            </li>
            <li>
              <a href="/about-us">Hakkımızda</a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="mobile-nav-menu">
        <div className="nav container">
          <ul>
            <li className="active">
              <a href="/">
                <div className="icon">
                  <svg
                    width="23"
                    height="20"
                    viewBox="0 0 23 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.5 18H17.5V8.15704L11.5 2.70304L5.5 8.15704V18ZM18.5 20H4.5C4.23478 20 3.98043 19.8947 3.79289 19.7071C3.60536 19.5196 3.5 19.2653 3.5 19V10H0.5L10.827 0.612042C11.0111 0.44452 11.2511 0.351685 11.5 0.351685C11.7489 0.351685 11.9889 0.44452 12.173 0.612042L22.5 10H19.5V19C19.5 19.2653 19.3946 19.5196 19.2071 19.7071C19.0196 19.8947 18.7652 20 18.5 20ZM7 12H9C9 12.6631 9.26339 13.299 9.73223 13.7678C10.2011 14.2366 10.837 14.5 11.5 14.5C12.163 14.5 12.7989 14.2366 13.2678 13.7678C13.7366 13.299 14 12.6631 14 12H16C16 13.1935 15.5259 14.3381 14.682 15.182C13.8381 16.0259 12.6935 16.5 11.5 16.5C10.3065 16.5 9.16193 16.0259 8.31802 15.182C7.47411 14.3381 7 13.1935 7 12Z" />
                  </svg>
                </div>
                <span>Ürünler</span>
              </a>
            </li>
            <li>
              <a href="/search">
                <div className="icon">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M18.531 16.617L22.814 20.899L21.399 22.314L17.117 18.031C15.5237 19.3082 13.542 20.0029 11.5 20C6.532 20 2.5 15.968 2.5 11C2.5 6.032 6.532 2 11.5 2C16.468 2 20.5 6.032 20.5 11C20.5029 13.042 19.8082 15.0237 18.531 16.617ZM16.525 15.875C17.7941 14.5699 18.5029 12.8204 18.5 11C18.5 7.132 15.367 4 11.5 4C7.632 4 4.5 7.132 4.5 11C4.5 14.867 7.632 18 11.5 18C13.3204 18.0029 15.0699 17.2941 16.375 16.025L16.525 15.875ZM12.678 7.176C12.3831 7.3092 12.125 7.51195 11.9257 7.76686C11.7264 8.02176 11.5919 8.32121 11.5338 8.63951C11.4757 8.95781 11.4957 9.28546 11.5921 9.59433C11.6885 9.90321 11.8583 10.1841 12.0871 10.4129C12.3159 10.6417 12.5968 10.8115 12.9057 10.9079C13.2145 11.0043 13.5422 11.0243 13.8605 10.9662C14.1788 10.9081 14.4782 10.7736 14.7331 10.5743C14.9881 10.375 15.1908 10.1169 15.324 9.822C15.5868 10.6756 15.5563 11.5926 15.2375 12.427C14.9186 13.2613 14.3297 13.9648 13.5646 14.4256C12.7994 14.8864 11.9021 15.0778 11.0155 14.9694C10.129 14.8609 9.3042 14.4589 8.67264 13.8274C8.04108 13.1958 7.63906 12.371 7.53064 11.4845C7.42223 10.5979 7.61365 9.70058 8.0744 8.93544C8.53516 8.1703 9.23875 7.58138 10.073 7.26252C10.9074 6.94366 11.8244 6.9132 12.678 7.176Z" />
                  </svg>
                </div>
                <span>Ara</span>
              </a>
            </li>
            {!isLogin ? (
              <li>
                <a onClick={openModal}>
                  <div className="icon">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4.5 22C4.5 19.8783 5.34285 17.8434 6.84315 16.3431C8.34344 14.8429 10.3783 14 12.5 14C14.6217 14 16.6566 14.8429 18.1569 16.3431C19.6571 17.8434 20.5 19.8783 20.5 22H18.5C18.5 20.4087 17.8679 18.8826 16.7426 17.7574C15.6174 16.6321 14.0913 16 12.5 16C10.9087 16 9.38258 16.6321 8.25736 17.7574C7.13214 18.8826 6.5 20.4087 6.5 22H4.5ZM12.5 13C9.185 13 6.5 10.315 6.5 7C6.5 3.685 9.185 1 12.5 1C15.815 1 18.5 3.685 18.5 7C18.5 10.315 15.815 13 12.5 13ZM12.5 11C14.71 11 16.5 9.21 16.5 7C16.5 4.79 14.71 3 12.5 3C10.29 3 8.5 4.79 8.5 7C8.5 9.21 10.29 11 12.5 11Z" />
                    </svg>
                  </div>
                  <span>Giriş Yap/Üye Ol</span>
                </a>
              </li>
            ) : (
              <li>
                <a href="/account">
                  <div className="icon">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M4.5 22C4.5 19.8783 5.34285 17.8434 6.84315 16.3431C8.34344 14.8429 10.3783 14 12.5 14C14.6217 14 16.6566 14.8429 18.1569 16.3431C19.6571 17.8434 20.5 19.8783 20.5 22H18.5C18.5 20.4087 17.8679 18.8826 16.7426 17.7574C15.6174 16.6321 14.0913 16 12.5 16C10.9087 16 9.38258 16.6321 8.25736 17.7574C7.13214 18.8826 6.5 20.4087 6.5 22H4.5ZM12.5 13C9.185 13 6.5 10.315 6.5 7C6.5 3.685 9.185 1 12.5 1C15.815 1 18.5 3.685 18.5 7C18.5 10.315 15.815 13 12.5 13ZM12.5 11C14.71 11 16.5 9.21 16.5 7C16.5 4.79 14.71 3 12.5 3C10.29 3 8.5 4.79 8.5 7C8.5 9.21 10.29 11 12.5 11Z" />
                    </svg>
                  </div>
                  <span>Hesabım</span>
                </a>
              </li>
            )}
            <li>
              <a href="/basket">
                <div className="icon">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4.5 16V4H2.5V2H5.5C5.76522 2 6.01957 2.10536 6.20711 2.29289C6.39464 2.48043 6.5 2.73478 6.5 3V15H18.938L20.938 7H8.5V5H22.22C22.372 5 22.522 5.03466 22.6586 5.10134C22.7952 5.16801 22.9148 5.26495 23.0083 5.38479C23.1019 5.50462 23.1668 5.6442 23.1983 5.79291C23.2298 5.94162 23.2269 6.09555 23.19 6.243L20.69 16.243C20.6358 16.4592 20.511 16.6512 20.3352 16.7883C20.1595 16.9255 19.9429 17 19.72 17H5.5C5.23478 17 4.98043 16.8946 4.79289 16.7071C4.60536 16.5196 4.5 16.2652 4.5 16ZM6.5 23C5.96957 23 5.46086 22.7893 5.08579 22.4142C4.71071 22.0391 4.5 21.5304 4.5 21C4.5 20.4696 4.71071 19.9609 5.08579 19.5858C5.46086 19.2107 5.96957 19 6.5 19C7.03043 19 7.53914 19.2107 7.91421 19.5858C8.28929 19.9609 8.5 20.4696 8.5 21C8.5 21.5304 8.28929 22.0391 7.91421 22.4142C7.53914 22.7893 7.03043 23 6.5 23ZM18.5 23C17.9696 23 17.4609 22.7893 17.0858 22.4142C16.7107 22.0391 16.5 21.5304 16.5 21C16.5 20.4696 16.7107 19.9609 17.0858 19.5858C17.4609 19.2107 17.9696 19 18.5 19C19.0304 19 19.5391 19.2107 19.9142 19.5858C20.2893 19.9609 20.5 20.4696 20.5 21C20.5 21.5304 20.2893 22.0391 19.9142 22.4142C19.5391 22.7893 19.0304 23 18.5 23Z" />
                  </svg>
                </div>
                <span>Sepetim</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {toggle && (
        <div
          className="overlay"
          id="open-account"
          onClick={(e) => closeModal(e)}
        >
          <div className="body">
            <div className="title">
              <ul>
                <li ref={loginTab} onClick={changeLoginTab} className="active">
                  <a href="#">Giriş Yap</a>
                </li>
                <li ref={accountTab} onClick={changeAccountTab}>
                  <a href="#">Üye Ol</a>
                </li>
              </ul>
            </div>
            <div className="content">
              {!change && (
                <div className="form-area">
                  <div className="welcome">
                    <span>Giriş Yap</span>
                  </div>
                  <div className="group">
                    <label htmlFor="text" className="email"></label>
                    <input
                      type="text"
                      name="text"
                      id="text"
                      placeholder="E-postan"
                    />
                  </div>
                  <div className="group">
                    <label htmlFor="pass" className="pass"></label>
                    <input
                      type="password"
                      name="pass"
                      id="pass"
                      placeholder="Şifren"
                    />
                  </div>
                  <div className="lost-password">
                    <a href="#">Şifremi Unuttum</a>
                  </div>
                  <div className="log-in">
                    <button>Giriş Yap</button>
                    <span>Veya</span>
                    <div className="third-party">
                      <div className="fb">
                        <a href="#">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10 0C4.477 0 0 4.477 0 10C0 14.991 3.657 19.128 8.438 19.879V12.89H5.898V10H8.438V7.797C8.438 5.291 9.93 3.907 12.215 3.907C13.309 3.907 14.453 4.102 14.453 4.102V6.562H13.193C11.95 6.562 11.563 7.333 11.563 8.124V10H14.336L13.893 12.89H11.563V19.879C16.343 19.129 20 14.99 20 10C20 4.477 15.523 0 10 0Z" />
                          </svg>
                          Facebook ile Giriş Yap
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {change && (
                <div className="form-area">
                  <div className="welcome">
                    <span>Üye Ol</span>
                  </div>
                  <div className="group">
                    <label htmlFor="text" className="email"></label>
                    <input
                      type="text"
                      name="text"
                      id="text"
                      placeholder="E-postan"
                    />
                  </div>
                  <div className="group">
                    <label htmlFor="pass" className="pass"></label>
                    <input
                      type="password"
                      name="pass"
                      id="pass"
                      placeholder="Şifren"
                    />
                  </div>
                  <div className="lost-password">
                    <a href="#">Şifremi Unuttum</a>
                  </div>
                  <div className="log-in">
                    <button>Giriş Yap</button>
                    <span>Veya</span>
                    <div className="third-party">
                      <div className="fb">
                        <a href="#">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10 0C4.477 0 0 4.477 0 10C0 14.991 3.657 19.128 8.438 19.879V12.89H5.898V10H8.438V7.797C8.438 5.291 9.93 3.907 12.215 3.907C13.309 3.907 14.453 4.102 14.453 4.102V6.562H13.193C11.95 6.562 11.563 7.333 11.563 8.124V10H14.336L13.893 12.89H11.563V19.879C16.343 19.129 20 14.99 20 10C20 4.477 15.523 0 10 0Z" />
                          </svg>
                          Facebook ile Giriş Yap
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </header>
  );
}
