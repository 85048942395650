import React from "react";

const loaderStyle = {
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  color:'red',
  fontSize:'50px'
}

export default function Loader() {
  return (
    <div className="loader" style={loaderStyle}>
      <p>loading</p>
    </div>
  );
}
